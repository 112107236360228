import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "path": "/new-stack",
  "date": "2021-02-08",
  "title": "New Stack",
  "tags": ["gatsby", "2021"],
  "excerpt": "New stack with Gatsby, React and Tailwind"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I'm trying a new stack for my personal site using Gatsby, React and Tailwind. I also want to experiment with more of a timeline-inspired design, similar to `}<a href='https://brianlovin.com/' target='_blank' rel='noopener noreferrer'>{`Brian Lovin's site`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      